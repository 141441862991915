import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import profilePic from '../../pages/me.jpg'
import get from 'lodash/get'
import './style.scss'
import '../../assets/fonts/fontello-771c82e0/css/fontello.css'
import '../../assets/fonts/font.css'

// Link component is meant for internal links so we wrap it in order to use an <a> for external links.
//
// Since DOM elements <a> cannot receive activeClassName and partiallyActive,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
    // This assumes that any internal link (intended for Gatsby) will start with
    // exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
        return <GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>{children}</GatsbyLink>;
    }

    return <a href={to} target="_blank" target="_blank" {...other}>{children}</a>;
}

class Sidebar extends React.Component {
  render() {
    const { location } = this.props
    const {
      author,
      subtitle,
      menu,
    } = this.props.data.site.siteMetadata
    const isHomePage = get(location, 'pathname', '/') === '/'

    /* eslint-disable jsx-a11y/img-redundant-alt */
    const authorBlock = (
      <div>
        <Link to="/">
          <img
            src={profilePic}
            className="sidebar__author-photo"
            width="75"
            height="75"
            alt={author.name}
          />
        </Link>
        {isHomePage ? (
          <h1 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/">
              {author.name}
            </Link>
          </h1>
        ) : (
          <h2 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/">
              {author.name}
            </Link>
          </h2>
        )}
        <p className="sidebar__author-subtitle">{subtitle}</p>
      </div>
    )
    /* eslint-enable jsx-a11y/img-redundant-alt */

    return (
      <div className="sidebar">
        <div className="sidebar__inner">
          <div className="sidebar__author">{authorBlock}</div>
          <div>
            <Menu data={menu} />
            <MenuLinks data={author} />
          </div>
        </div>
      </div>
    )
  }
}

class Menu extends React.Component {
  render() {
    const menu = this.props.data

    const menuBlock = (
      <ul className="menu__list">
        {menu.map(item => (
          <li className="menu__list-item" key={item.path}>
            <Link
              to={item.path}
              className="menu__list-item-link"
              activeClassName="menu__list-item-link menu__list-item-link--active"
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    )

    return <nav className="menu">{menuBlock}</nav>
  }
}

class MenuLinks extends React.Component {
  render() {
    const author = this.props.data
    const links = {
      github: author.github,
      instagram: author.instagram,
      rss: author.rss,
      email: author.email,
    }

    return (
      <div className="menu-links">
        <ul className="menu-links__list">

          <li className="menu-links__list-item">
            <a href={links.email === '' ? `/contact` : `mailto:${links.email}`}>
              <i className="icon-mail" />
            </a>
          </li>

          <li className="menu-links__list-item">
            <a
              href={`https://github.com/${links.github}`}
              target="_blank"
              rel="noopener noreferrer" >
              <i className="icon-g" />
            </a>
          </li>

          <li className="menu-links__list-item">
            <a
              href={`https://instagram.com/${links.instagram}`}
              target="_blank"
              rel="noopener noreferrer" >
              <i className="icon-i" />
            </a>
          </li>

          <li className="menu-links__list-item">
            <a href={links.rss}>
              <i className="icon-r" />
            </a>
          </li>

        </ul>
      </div>
    )
  }
}

export default Sidebar
